import request from '@/utils/request'

const api = {
  contractListPage: '/contract/contractListPage',
  contractAdd: '/contract/add',
  contractUpdate: '/contract/update',
  advertiserList: 'advertiser/advertiserList', // 广告主
  agentList: 'agent/agentList', // 代理
  businessUserList: 'user/businessUserList',
  incomeList: 'income/incomeList',
  capitalDetail: 'capital/capitalDetail', // 资金管理
  getTransferAdvertiserList: 'capital/getTransferAdvertiserList', // 广告主列表
  transfer: 'capital/transfer' // 广告主转账
}

export default api

export function contractListPage (data) {
  return request({
    url: api.contractListPage,
    method: 'post',
    data
  })
}
export function contractAdd (data) {
  return request({
    url: api.contractAdd,
    method: 'post',
    data
  })
}
export function contractUpdate (data) {
  return request({
    url: api.contractUpdate,
    method: 'post',
    data
  })
}
export function advertiserList (data) {
  return request({
    url: api.advertiserList,
    method: 'post',
    data
  })
}
export function agentList (data) {
  return request({
    url: api.agentList,
    method: 'post',
    data
  })
}
export function businessUserList (data) {
  return request({
    url: api.businessUserList,
    method: 'get'
  })
}
export function incomeList (data) {
  return request({
    url: api.incomeList,
    method: 'post',
    data
  })
}
export function capitalDetail (data) {
  return request({
    url: api.capitalDetail,
    method: 'get',
    data
  })
}
export function getTransferAdvertiserList (data) {
  return request({
    url: api.getTransferAdvertiserList,
    method: 'get',
    data
  })
}
export function transfer (data) {
  return request({
    url: api.transfer,
    method: 'post',
    data
  })
}
