<template>
  <div class="sonfig-container">
    <div class="sonfig_left">
      <a-menu :default-selected-keys="['1']" mode="inline" type="inner" @click="handleClick">
        <a-menu-item key="1"> 广告主加款 </a-menu-item>
        <a-menu-item key="2"> 广告主退款 </a-menu-item>
      </a-menu>
    </div>
    <div class="sonfig_right">
      <div class="sonfig_operate">
        <div v-if="this.keys === '1' || this.keys === ''">
          <a-descriptions title="第一步：选择要加款的广告主" style="margin: 20px 0">
            <a-descriptions-item>
              <a-table
                class="m_table_cust"
                bordered
                :row-selection="{ onChange: onSelectChange, type: 'radio' }"
                :pagination="false"
                :columns="columns"
                :data-source="dataList"
                :rowKey="
                  (record, index) => {
                    return index
                  }
                "
                :loading="loading"
                v-if="this.keys === '1' || this.keys === ''"
              >
              </a-table>
              <m-empty v-else>
                <template #description>暂无数据！</template>
              </m-empty>
            </a-descriptions-item>
          </a-descriptions>
          <div>
            <h1>第二步：转出方(代理商)信息</h1>
            <div class="descriptions_second">
              <div><span>代理商名称:</span> {{ this.capitalPush.partyName }}</div>
              <div><span>代理商账户总余额:</span> {{ this.capitalPush.remainAmount }}</div>
            </div>
          </div>
          <div style="margin: 30px 0">
            <h1>
              第三步：加款金额
              <span class="descThird">加款金额，不能超过代理商账户总余额</span>
            </h1>
            <a-descriptions class="increase">
              <div><span style="color: #999">被加款广告主:</span> {{ this.partyNamePush }}</div>
              <a-descriptions-item label="加款金额">
                <a-input placeholder="请输入加款金额" v-model.trim="transferAmount" @input="onChangePus"></a-input>
              </a-descriptions-item>
            </a-descriptions>
          </div>
          <div class="anniu">
            <a-button type="primary" @click="addNew">加款</a-button>
          </div>
        </div>
        <div v-if="this.keys === '2'">
          <a-descriptions title="第一步：选择要退款的广告主" style="margin: 20px 0">
            <a-descriptions-item>
              <a-table
                class="m_table_cust"
                bordered
                :row-selection="{ onChange: onSelectChange, type: 'radio' }"
                :pagination="false"
                :columns="columns"
                :data-source="dataList"
                :rowKey="
                  (record, index) => {
                    return index
                  }
                "
                :loading="loading"
                v-if="this.keys === '2'"
              >
              </a-table>
              <m-empty v-else>
                <template #description>暂无数据！</template>
              </m-empty>
            </a-descriptions-item>
          </a-descriptions>
          <div>
            <h1>第二步：回收方(代理商)信息</h1>
            <div class="descriptions_second">
              <div><span>代理商名称:</span> {{ this.capitalPush.partyName }}</div>
              <div><span>代理商账户总余额:</span> {{ this.capitalPush.remainAmount }}</div>
            </div>
          </div>
          <div style="margin: 30px 0">
            <h1>
              第三步：退款金额
              <span class="descThird">退款金额，不能超过广告主账户总余额</span>
            </h1>
            <a-descriptions class="increase">
              <div><span style="color: #999">被退款广告主:</span> {{ this.partyNamePush }}</div>
              <a-descriptions-item label="退款金额">
                <a-input placeholder="请输入退款金额" v-model.trim="transferAmount" @input="onChangePus"></a-input>
              </a-descriptions-item>
            </a-descriptions>
          </div>
          <div class="anniu">
            <a-button type="primary" @click="addNew">退款</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTransferAdvertiserList, capitalDetail, transfer } from '@/api/financialManagementB'
import mixDate from '@/mixins/initDate'
// import mixDate from '@/mixins/initDate'
const columns = [
  {
    dataIndex: 'partyName',
    title: '广告主名称',
    key: 'partyName',
    align: 'center',
    with: 260,
    ellipsis: true
  },

  {
    dataIndex: 'zijinIncomeAmount',
    title: '账户总充值',
    key: 'zijinIncomeAmount',
    with: 120,
    ellipsis: true,
    align: 'center'
  },
  {
    dataIndex: 'zijinRemainAmount',
    title: '账户余额',
    key: 'zijinRemainAmount',
    align: 'center'
  },
  {
    dataIndex: 'zijinExpenseAmount',
    title: '总消耗',
    key: 'zijinExpenseAmount',
    align: 'center'
  }
]
const columnsPush = [
  {
    dataIndex: 'agentCompany',
    title: '公司名称',
    key: 'agentCompany',
    align: 'center',
    width: 200,
    ellipsis: true
  },
  {
    dataIndex: 'agentName',
    title: '代理商名称',
    key: 'agentName',
    align: 'center'
  },
  {
    dataIndex: 'username',
    title: '客户经理',
    key: 'username',
    align: 'center'
  },
  {
    dataIndex: 'addTime',
    title: '创建日期',
    key: 'addTime',
    align: 'center'
  },
  {
    dataIndex: 'agentSts',
    title: '账号状态',
    key: 'agentSts',
    align: 'center',
    customRender: (text, record, index) => {
      if (record.agentSts === '0') {
        text = '待审核'
      } else if (record.agentSts === '1') {
        text = '审核通过'
      } else if (record.agentSts === '-1') {
        text = '审核不通过'
      }
      return text
    }
  },
  {
    dataIndex: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'center'
  }
]
export default {
  name: 'Sonfig',
  mixins: [mixDate],
  data () {
    return {
      transferAmount: '',
      adverView: '',
      columns,
      columnsPush,
      supplier: 1,
      loading: false,
      addAbtestVisible: false,
      addGroupVisible: false,
      appList: [],
      placeList: [],
      industry: [],
      currentGroup: [],
      groupList: [],
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      BusNames: { label: 'name', value: 'id' },
      pid: [],
      querys: {},
      total: 0,
      businessUser: [],
      pidBus: [],
      dateStrings: [],
      dateString: [],
      manager: undefined,
      dataList: [],
      times: [],
      advertiserIndustry: '',
      businessUserPush: '',
      keys: '1',
      types: '',
      userNames: {},
      rolesPush: '',
      capitalPush: {},
      transferType: '1',
      partyIdpush: '',
      advertiserIdPush: '',
      zijinRemainAmount: '',
      selectedRowKeys: [],
      partyNamePush: ''
    }
  },
  computed: {},
  mounted () {
    this.getTransferAdvertiserList()
    this.capitalDetail()
  },
  watch: {
    handler (val) {
      if (val.tradePid && val.tradeId) {
        this.pid = [val.tradePid, val.tradeId]
        this.query = JSON.parse(JSON.stringify(val))
      }
    }
  },
  methods: {
    onSelectChange (selectedRowKeys, selectionRows) {
      this.advertiserIdPush = selectionRows[0].id
      this.partyNamePush = selectionRows[0].partyName
      this.selectedRowKeys = selectedRowKeys
      this.zijinRemainAmount = selectionRows[0].zijinRemainAmount
    },
    onChangePus () {
      this.$forceUpdate()
      if (this.keys === '1') {
        if (this.transferAmount > this.capitalPush.remainAmount) {
          this.$message.error('加款金额不得大于账户总余额')
          this.transferAmount = this.capitalPush.remainAmount
        }
      } else if (this.keys === '2') {
        if (this.transferAmount > this.zijinRemainAmount) {
          this.$message.error('加款金额不得大于账户余额')
          this.transferAmount = this.zijinRemainAmount
        }
      }
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间
      return current && current > this.initDate(0, 0).end
    },
    handleClick (e) {
      this.keys = e.key
      if (this.keys === '1') {
        this.transferType = 1
      } else {
        this.transferType = 2
      }
    },
    // 广告主列表
    async getTransferAdvertiserList () {
      const res = await getTransferAdvertiserList()
      if (res.code === 200) {
        this.dataList = res.data
      }
    },
    // 代理商资金 capitalDetail
    async capitalDetail () {
      const res = await capitalDetail()
      if (res.code === 200) {
        this.capitalPush = res.data
        this.partyIdpush = res.data.partyId
      }
    },
    // 广告主转账 transfer
    async transfer () {
      const res = await transfer(this.querys)
      if (res.code === 200) {
        if (this.keys === '1') {
          this.$message.success('加款成功!')
          // this.$router.go(0)
          this.timer = setTimeout(() => {
            this.getTransferAdvertiserList()
            this.capitalDetail()
          }, 1000)
        } else if (this.keys === '2') {
          this.$message.success('退款成功!')
          // this.$router.go(0)
          this.timer = setTimeout(() => {
            this.getTransferAdvertiserList()
            this.capitalDetail()
          }, 1000)
        }
      }
      this.selectedRowKeys = []
      this.transferAmount = ''
    },
    clearData () {
      this.selectedRowKeys = [] // 调用这个方法就有效果了
    },
    addNew () {
      if (this.keys === '1') {
        if (this.transferAmount <= this.capitalPush.remainAmount) {
          this.querys = {
            agentId: this.partyIdpush,
            advertiserId: this.advertiserIdPush,
            transferAmount: this.transferAmount,
            transferType: this.transferType
          }

          this.transfer(this.querys)
        } else {
          this.$message.error('加款金额不得大于账户总余额')
        }
      } else if (this.keys === '2') {
        if (this.transferAmount <= this.zijinRemainAmount) {
          this.querys = {
            agentId: this.partyIdpush,
            advertiserId: this.advertiserIdPush,
            transferAmount: this.transferAmount,
            transferType: this.transferType
          }

          this.transfer(this.querys)
        } else {
          this.$message.error('加款金额不得大于账户总余额')
        }
      }
    },
    // 新增广告主
    handleClickAdd (type) {
      this.types = type
    },
    changeTradeId (val) {
      if (val.length > 0) {
        this.query.tradePid = val[0]
        this.query.tradeId = val[1]
      }
      this.advertiserIndustry = this.query.tradeId
      this.query = {
        page: 1,
        limit: 10,
        startTime: this.times[0] || '',
        endTime: this.times[1] || '',
        advertiserIndustry: this.advertiserIndustry || '',
        businessUser: this.businessUserPush || ''
      }
      this.timer = setTimeout(() => {
        // if (this.keys === 1 || this.keys === '') {
        //   this.getAdvertiserListPage()
        // } else if (this.keys === 2) {
        //   this.getAgentListPage()
        // }
      }, 1000)
    },
    changeBusiness (val) {},
    handleProvinceChange (value) {
      this.manager = value
      this.businessUserPush = this.manager
      this.query = {
        page: 1,
        limit: 10,
        startTime: this.times[0] || '',
        endTime: this.times[1] || '',
        advertiserIndustry: this.advertiserIndustry || '',
        businessUser: this.businessUserPush || ''
      }
      this.timer = setTimeout(() => {
        // if (this.keys === '1' || this.keys === '') {
        //   this.getAdvertiserListPage()
        // } else if (this.keys === '2') {
        //   this.getAgentListPage()
        // }
      }, 1000)
    },

    // 分页
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.timer = setTimeout(() => {
        // if (this.keys === '1' || this.keys === '') {
        //   this.getAdvertiserListPage()
        // } else if (this.keys === '2') {
        //   this.getAgentListPage()
        // }
      }, 1000)
    },
    handleChangePage (page) {
      this.query.page = page
      this.timer = setTimeout(() => {
        // if (this.keys === '1' || this.keys === '') {
        //   this.getAdvertiserListPage()
        // } else if (this.keys === '2') {
        //   this.getAgentListPage()
        // }
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.anniu {
  margin-top: 20px;
  text-align: left;
}
.sonfig-container {
  padding: 0 10px;
  display: flex;
  .sonfig_left {
    width: 20%;
    max-width: 200px;
    min-width: 180px;
    margin-right: 10px;
    min-height: 80vh;
    background: #fff;
    padding: 20px 0;
    border-radius: 5px;
    ::v-deep .ant-menu-item.ant-menu-item-selected::after {
      border-right: none;
      border-left: none;
    }
    ::v-deep .ant-menu-inline {
      border-right: none;
    }
  }
  .sonfig_right {
    flex-grow: 1;
    .sonfig_search {
      background: #fff;
      padding: 10px 5px;
      position: relative;
      .search_item {
        width: 22%;
        min-width: 260px;
        margin-right: 10px;
      }
    }
    .sonfig_operate {
      background: #fff;
      display: flex;
      padding: 0 20px;
      min-height: 80vh;
      border-radius: 5px;
      .descriptions_second {
        background: #f9f9f9;
        max-width: 34%;
        padding: 15px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        div {
          display: flex;
          flex-direction: row;
          span {
            margin-right: 5px;
            color: #999;
          }
        }
        div:last-child {
          margin-left: 20px;
        }
      }
    }
    .descThird {
      font-size: 14px;
      color: #999;
      margin-left: 20px;
    }
    .increase {
      background: #f9f9f9;
      border: none;
      border-radius: 5px;
      max-width: 55%;
      ::v-deep {
        .ant-descriptions-item-content {
          width: 80%;
        }
      }
    }
    .sonfig_operate_two {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    .sonfig_table {
      background: #fff;
      padding: 10px;
      overflow: hidden;
      .pagination {
        padding: 10px 0;
      }
    }
    .m_table_cust {
      padding: 0px;
    }
    ::v-deep {
      .ant-table-bordered .ant-table-body > table {
        border: none;
      }
      .ant-table-bordered .ant-table-thead > tr > th,
      .ant-table-bordered .ant-table-tbody > tr > td {
        border-right: none;
      }
      .ant-table-tbody > tr > td,
      .ant-table-thead > tr > th {
        border-bottom: none;
      }
      .ant-table-thead > tr > th {
        padding: 14px 16px;
      }
      .ant-descriptions-row > td {
        padding: 12px;
      }
      .ant-descriptions-item-label {
        color: #999;
      }
      .ant-table-tbody > tr.ant-table-row-selected td {
        background: none;
      }
    }
  }
}
</style>
